import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { ICON_UPLOAD } from '@apprentage/constants';
import { useFlags } from 'flagsmith/react';
import {
  FLAG_USERS_READONLY,
  NOTICE_USERS_READONLY_BODY,
  NOTICE_USERS_READONLY_TITLE
} from '../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../actions/FeatureFlags';
import * as aws from '../../../services/aws';
import { updateUser } from '../../../services/user';
import { getCurrentUser } from '../../../actions/Users';
import { DEFAULT_PROFILE_PIC } from '../../../constants/assets';
import ImageUploader from '../ImageUploader';
import {
  ProfilePictureIconContainer, ProfilePictureImgAndButtonContainer, ProfilePictureImgContainer
} from './styles';

const ProfilePicture = ({
  children,
  className = '',
  height = '150',
  width = '150'
}) => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_USERS_READONLY]);
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // local state
  const [imagePreview, setImagePreview] = useState('');
  // misc
  const orgId = organization?.id || '';
  const profilePicture = currentUser?.profilePicture || '';
  const name = currentUser?.name || '';
  const hasProfilePicture = !!(profilePicture && profilePicture !== '');
  const testUser = currentUser?.testUser || null;

  useEffect(() => {
    $(document).on('click', '.profilePicture-stop-propagation', (e) => {
      e.stopPropagation();
    });
  }, []);

  const updateProfile = ({ url }) => {
    updateUser({
      profilePicture: url
    }, currentUser?.id).then(() => {
      if (hasProfilePicture) {
        aws.deleteFile({
          url: profilePicture,
          orgId
        });
      }

      dispatch(getCurrentUser({ userId: currentUser?.id }));
    });
  };

  const onImageUploadCallback = ({ url }) => {
    setImagePreview(url);
    updateProfile({ url });
  };

  const previewFile = (file) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      // convert image file to base64 string
      setImagePreview(reader.result);
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const browseFiles = () => {
    if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_USERS_READONLY_TITLE,
        modalBody: NOTICE_USERS_READONLY_BODY
        // preventClose: true
      }));
      return;
    }
    document.getElementById('profilePicture').click();
  };

  if (!currentUser.id || !organization.id) {
    return null;
  }

  return (
    <div
      className={`profile-picture-container ${className}`}
    >
      <ProfilePictureImgAndButtonContainer
        height={height}
        width={width}
      >
        <ProfilePictureImgContainer
          height={height}
          width={width}
        >
          <img
            src={imagePreview || profilePicture || DEFAULT_PROFILE_PIC}
            className="w-100"
            alt={name}
          />
        </ProfilePictureImgContainer>
        <ProfilePictureIconContainer
          className="btn btn-sm btn-white profilePicture-stop-propagation"
          onClick={browseFiles}
          role="button"
        >
          <i className={ICON_UPLOAD} />
        </ProfilePictureIconContainer>
        {!testUser && (
          <ImageUploader
            inputId="profilePicture"
            name="profilePicture"
            contentId={currentUser.id}
            contentType="user"
            style={{ opacity: 0, height: '1px' }}
            onImageUploadCallback={onImageUploadCallback}
            onImagePreviewCallback={previewFile}
            saveToCdnFiles={false}
          />
        )}
      </ProfilePictureImgAndButtonContainer>

      {children && (
        <>
          {children}
        </>
      )}
    </div>
  );
};

export default ProfilePicture;
