import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { FaListAlt, FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useFlags, useFlagsmith } from 'flagsmith/react';
import { RecordState } from '../../components/AudioReactRecorder';
import {
  FLAG_LOGBOOKS_VELA_READONLY,
  NOTICE_LOGBOOKS_READONLY_TITLE,
  NOTICE_MAINTENANCE_MODE_BODY
} from '../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../actions/FeatureFlags';
import { withAuthorization } from '../../components/Session';
import {
  LOGBOOK, LOGBOOKS, RECORDS, SLASH
} from '../../constants/routes';
import { canAccessLogbook } from '../../services/currentUser';
import { errorLogbookNotFound, getLogbook, resetLogbook } from '../../actions/Logbooks';
import { getGreeting } from '../../utils/date';
import { getApprenticeship, resetApprenticeship } from '../../actions/Apprenticeships';
import { goToElem } from '../../services/manageContent';
import { getLogbookRecords, resetLogbookRecords } from '../../actions/LogbookRecords';
import Loading from '../../components/Loading';
import RecordForm from '../../components/RecordForm';
import RecordAudioForm from '../../components/RecordAudioForm';
import RecordContainer from './RecordContainer';
import ProgramContainer from '../../components/Lists/ProgramsList/ProgramContainer';
import LogbookNotFound from '../../components/LogbookNotFound';
import LogbookProgress from '../../components/LogbookProgress';
import LogbookRecords from '../../components/LogbookRecords';
import NoteTypeNav from '../../components/MaterialForm/NoteTypeNav';
import AudioNotes from '../../components/MaterialForm/AudioNotes';
import './style.css';

const Logbook = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const flags = useFlags([FLAG_LOGBOOKS_VELA_READONLY]);
  const { getFlags } = useFlagsmith();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbook = useSelector((state) => state.logbook);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  // Params
  const searchParams = new URLSearchParams(window.location.search);
  const logbookId = searchParams.get('logbookId');
  const postNewRecord = searchParams.get('postNewRecord');
  // Current User
  const userId = currentUser?.id || '';
  const role = currentUser?.role || [];
  const apprenticeshipIds = currentUser?.apprenticeshipIds || [];
  const firstName = currentUser?.firstName || null;
  // Organization
  const orgId = organization?.id || '';

  const [loading, setLoading] = useState(false);
  const [showRecordForm, setShowRecordForm] = useState(false);
  const [noteType, setNoteType] = useState('AUDIO');
  const [recordState, setRecordState] = useState(RecordState.NONE);
  const [audioNotes, setAudioNotes] = useState([]);

  const greeting = useMemo(() => {
    return getGreeting();
  }, []);

  const recordFormCallback = () => {
    goToElem('TopNav');

    if (postNewRecord) {
      history.replace(`${RECORDS}?logbookId=${logbookId}`);
    }

    setShowRecordForm(false);
  };

  const resetAudioState = () => {
    setAudioNotes([]);
    setRecordState(RecordState.NONE);
  };

  const audioRecordFormCallback = () => {
    resetAudioState();
    recordFormCallback();
  };

  const handleChangeNoteType = (type) => {
    if (noteType === 'AUDIO' && type === 'TEXT') {
      resetAudioState();
    }

    setNoteType(type);
  };

  const handleShowCreateRecordForm = useCallback(() => {
    getFlags();

    if (flags?.logbooks_vela_readonly?.enabled && flags?.logbooks_vela_readonly?.value !== undefined) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_LOGBOOKS_READONLY_TITLE,
        modalBody: NOTICE_MAINTENANCE_MODE_BODY
        // preventClose: true
      }));
      setShowRecordForm(false);
      if (postNewRecord) {
        history.replace(`${LOGBOOK}?logbookId=${logbookId}`);
      }
      return;
    }

    if (postNewRecord) {
      setShowRecordForm(true);
    } else {
      history.push(`${LOGBOOK}?logbookId=${logbookId}&postNewRecord=true`);
    }

    setTimeout(() => {
      goToElem('RecordForm');
    }, 50);
  }, [dispatch, flags?.logbooks_vela_readonly?.enabled, flags?.logbooks_vela_readonly?.value, getFlags, history, logbookId, postNewRecord]);

  useEffect(() => {
    dispatch(resetLogbookRecords());

    dispatch(getLogbook({
      id: logbookId,
      orgId,
      logbookHours: true
    })).then((response) => {
      if (response?.orgId !== currentUser?.orgId) {
        toast.error('Logbook Organization Access Denied ', { autoClose: false });
        history.replace(SLASH);
        return;
      }

      if (response?.userId !== currentUser?.id) {
        toast.error('Logbook User Access Denied', { autoClose: false });
        history.replace(SLASH);
        return;
      }

      dispatch(getLogbookRecords({ logbookId, limit: 1 }));

      if (response?.apprenticeshipId) {
        dispatch(getApprenticeship(response?.apprenticeshipId));
      }
    }).catch((error) => {
      console.error(error);
      dispatch(errorLogbookNotFound({
        logbookId,
        orgId: organization?.id,
        orgName: organization?.name,
        userId,
        userName: currentUser.name,
        userEmail: currentUser?.email,
        userLocationId: currentUser?.locationId
      }));
    });

    return function cleanup() {
      dispatch(resetApprenticeship());
      dispatch(resetLogbook());
    };
  }, [currentUser?.email, currentUser?.id, currentUser?.locationId, currentUser.name, currentUser?.orgId, dispatch, history, logbookId, orgId, organization?.id, organization?.name, userId]);

  useEffect(() => {
    if (postNewRecord === 'true') {
      handleShowCreateRecordForm();
    } else {
      setShowRecordForm(false);
    }
  }, [handleShowCreateRecordForm, postNewRecord]);

  if (!currentUser.id || !organization.id) {
    return (
      <Loading
        className="position-relative"
        style={{ background: '#121212' }}
      />
    );
  }

  if (!apprenticeshipIds || (Array.isArray(apprenticeshipIds) && apprenticeshipIds.length === 0)) {
    return <Redirect to={SLASH} />;
  }

  if (!canAccessLogbook(role)) {
    toast.error('You don\'t have access to Logbooks.');

    return <Redirect to={SLASH} />;
  }

  if (!logbookId) {
    toast.error('Missing Logbook ID');
    return <Redirect to={LOGBOOKS} />;
  }

  if (logbookId && !logbook?.id && !logbook.fetched) {
    return (
      <Loading
        className="position-relative"
        style={{ background: '#121212' }}
      />
    );
  }

  if (logbookId && !logbook?.id && logbook.fetched) {
    return (
      <LogbookNotFound />
    );
  }

  // if (!browserSupportsSpeechRecognition) {
  //   return <span>Browser doesn't support speech recognition.</span>;
  // }

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-sm-9 col-lg-6">

        {showRecordForm && (
          <>
            <NoteTypeNav
              className="mt-3"
              setSelectedTab={handleChangeNoteType}
              selectedTab={noteType}
              disabled={loading}
              text
              audio
            />

            {noteType === 'TEXT' && (
              <div className="my-3">
                <div className="welcomeMessage animateIn">
                  <div
                    className="Box my-3 px-5 py-3 rounded shadow flex-column d-flex justify-content-center"
                    style={{
                      border: '1px solid #1d1d1d'
                    }}
                  >
                    <div className='BoxSubText'>
                      {greeting}, {firstName}
                    </div>
                    <div className='BoxText'>
                      What <span style={{ color: '#bb86fc' }}>tasks</span> did you work
                      on today?
                    </div>
                  </div>
                </div>

                <RecordForm
                  onSuccessCallback={recordFormCallback}
                  onCancelCallback={recordFormCallback}
                  onErrorCallback={recordFormCallback}
                />
              </div>
            )}

            {noteType === 'AUDIO' && (
              <div className="my-3">
                <RecordAudioForm
                  loading={loading}
                  setLoading={setLoading}
                  onSuccessCallback={audioRecordFormCallback}
                  onErrorCallback={audioRecordFormCallback}
                  recordState={recordState}
                  audioNotes={audioNotes}
                />

                <AudioNotes
                  theme="dark"
                  setAudioNotes={setAudioNotes}
                  audioNotes={audioNotes}
                  recordState={recordState}
                  setRecordState={setRecordState}
                />
              </div>
            )}
          </>
        )}

        {logbook?.id && !showRecordForm && (
          <RecordContainer
            className='my-3'
            style={{
              minHeight: 'auto',
              background: 'none',
              border: '1px #1d1d1d solid'
            }}
          >
            <button
              type="button"
              className='btn btn-md btn-primary'
              onClick={handleShowCreateRecordForm}
            >
              <span className='d-flex align-items-center'>
                <FaPlus size={15} />
                <span className='ml-2'>
                  New Record
                </span>
              </span>
            </button>
          </RecordContainer>
        )}

        <div
          className={`my-3 ${showRecordForm && 'filter-blur-5'}`}
          style={{ minHeight: '100px' }}
        >
          {logbook?.id && apprenticeship?.id && (
            <ProgramContainer
              className="animateIn"
              apprenticeshipId={apprenticeship?.id}
              apprenticeshipTitle={apprenticeship?.title}
              logbookId={logbook?.id}
            >
              <div className='w-100'>
                <LogbookProgress
                  className='w-100'
                  records={logbookRecords.list}
                  ojtHours={apprenticeship?.ojtHours}
                />

                <LogbookRecords
                  data={logbookRecords.list}
                  className="w-100 mt-4"
                />

                {Array.isArray(logbookRecords?.list) && logbookRecords.list.length !== 0 && (
                  <RecordContainer
                    className={`my-3 ${showRecordForm && 'filter-blur-5'}`}
                    style={{
                      minHeight: 'auto',
                      // background: 'none',
                      border: '1px #1d1d1d solid'
                    }}
                  >
                    <Link
                      className='btn btn-md btn-link text-white'
                      disabled={showRecordForm}
                      to={`${RECORDS}?logbookId=${logbook?.id}`}
                    >
                      <span className='d-flex align-items-center'>
                        <FaListAlt />
                        <span className='ml-2'>
                          View all records ({logbookRecords?.pagination?.total})
                        </span>
                      </span>
                    </Link>
                  </RecordContainer>
                )}
              </div>
            </ProgramContainer>
          )}
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(Logbook);
