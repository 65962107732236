import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { parseDate } from 'chrono-node';
import * as aws from '../../services/aws';
import heapAnalytics from '../../utils/heapAnalytics';
import { createLogbookRecord, updateLogbookRecord } from '../../services/logbookRecords';
import { logbookRecordFromTranscript, transcribeAudio } from '../../services/openApi';
import { dayMdyDate, getGreeting, mdySimple } from '../../utils/date';
import { RecordState } from '../AudioReactRecorder';
import { getLogbook } from '../../actions/Logbooks';
import { getLogbookRecords } from '../../actions/LogbookRecords';
import { audioMimeTypeExtMap } from '../../constants/globals';
import { AWS_USER_PREFIX } from '../../constants/aws';
import { createResource } from '../../services/resources';
import LoadingWave from '../../routes/Logbook/LoadingWave';
import LogbookRecord from '../LogbookRecord';

const RecordAudioForm = ({
  loading,
  setLoading,
  recordState,
  audioNotes,
  onSuccessCallback,
  onErrorCallback
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const userLocation = useSelector((state) => state.userLocation);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbook = useSelector((state) => state.logbook);

  // Organization
  const orgId = organization?.id || null;
  // Current User
  const userId = currentUser?.id || null;
  const firstName = currentUser?.firstName || null;
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;
  const competencies = apprenticeship?.competencies || {};
  // Local State
  const [selectedCompetencyIds, setSelectedCompetencyIds] = useState([]);
  const [taskDate, setTaskDate] = useState(null);
  const [taskHours, setTaskHours] = useState(0);
  const [transcript, setTranscript] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');
  const [logbookRecordId, setLogbookRecordId] = useState(null);

  const mockRecord = useMemo(() => {
    return {
      id: 'temp-id',
      competencyIds: selectedCompetencyIds,
      date: taskDate,
      hours: taskHours,
      transcript: currentText
    };
  }, [currentText, selectedCompetencyIds, taskDate, taskHours]);

  const greeting = useMemo(() => {
    return getGreeting();
  }, []);

  const createRecordTrackingEvent = useCallback((eventName, { logbookId }) => {
    heapAnalytics.track(eventName, {
      type: 'voice',
      ...(logbookId ? { logbookId } : {}),
      orgId,
      apprenticeshipId,
      userId,
      employerId: userLocation?.id
    });
  }, [apprenticeshipId, orgId, userId, userLocation?.id]);

  const onError = useCallback((trackingEvent, error) => {
    const trackingData = {
      audio: true,
      logbookId: logbook?.id
    };

    console.error(trackingEvent);

    if (error) {
      trackingData.error = JSON.stringify(error);
      console.error(error);
    }

    createRecordTrackingEvent(trackingEvent, trackingData);

    setLoading(false);

    onErrorCallback();
  }, [createRecordTrackingEvent, logbook?.id, onErrorCallback, setLoading]);

  const uploadAudioFail = useCallback((error) => {
    toast.error('Something went wrong uploading your audio file, please try again.');
    onError('Error - Record - Create - Upload Audio', error);
  }, [onError]);

  const transcribeFail = useCallback((error) => {
    toast.error('Something went wrong transcribing your audio file, please try again.');
    onError('Error - Record - Create - Transcribe Audio', error);
  }, [onError]);

  const logbookRecordFromTranscriptFail = useCallback((error) => {
    onError('Error - Record - Create - Extract Transcript Data', error);
  }, [onError]);

  const createLogbookRecordFail = useCallback((error) => {
    toast.error('Something went wrong creating Logbook Record, please try again.');
    onError('Error - Record - Create', error);
  }, [onError]);

  const transcribeCreateRecord = useCallback(async () => {
    let newRecordId = null;
    let awsAudioFileUrl = null;
    let awsAudioFileId = null;
    const audioFile = audioNotes[0];
    const duration = audioFile?.duration;
    const type = audioFile?.blob?.type;
    const size = audioFile?.blob?.size;
    const fileExt = audioMimeTypeExtMap[type];

    const newRecord = {
      date: new Date().toISOString(),
      competencyIds: null,
      hours: 0,
      transcript: null,
      orgId,
      userId,
      recordType: 'audio',
      logbookId: logbook?.id,
      apprenticeshipId: logbook?.apprenticeshipId, // TODO Deprecate
      locationId: logbook?.locationId || logbook?.employerId // TODO Deprecate
    };

    // Upload Audio
    try {
      const responseFile = await aws.uploadFile({
        prefix: `${AWS_USER_PREFIX}/${orgId}`,
        file: audioFile?.blob,
        fileExt,
        orgId
      });

      awsAudioFileId = responseFile?.id;
      awsAudioFileUrl = responseFile?.url;
    } catch (error) {
      uploadAudioFail(error);
    }

    // ERROR: Audio Upload
    if (!awsAudioFileId || !awsAudioFileUrl) {
      uploadAudioFail();
      return;
    }

    // Transcribe Audio
    try {
      const responseTranscribed = await transcribeAudio({
        orgId,
        url: awsAudioFileUrl
      });

      if (responseTranscribed?.text) {
        // Transcript
        newRecord.transcript = responseTranscribed?.text;
        // Date from Transcript
        newRecord.date = parseDate(newRecord.transcript) || new Date().toISOString();
        // Set Transcript
        setTranscript(newRecord.transcript);
      }

      if (newRecord?.date) {
        setTaskDate(newRecord.date);
      }
    } catch (error) {
      transcribeFail(error);
    }

    // ERROR: No Transcript
    if (!newRecord.transcript) {
      transcribeFail();
      return;
    }

    // Extract Hours, Competencies & Date from transcript
    try {
      const responseLogbookData = await logbookRecordFromTranscript({
        transcript: newRecord.transcript,
        apprenticeshipId,
        userId,
        orgId
      });

      if (responseLogbookData?.hoursWorked) {
        newRecord.hours = responseLogbookData?.hoursWorked;
        setTaskHours(responseLogbookData?.hoursWorked);
      }

      if (Array.isArray(responseLogbookData?.competencyIds) && responseLogbookData?.competencyIds.length > 0) {
        newRecord.competencyIds = responseLogbookData?.competencyIds;
        setSelectedCompetencyIds(responseLogbookData?.competencyIds);
      }
    } catch (error) {
      logbookRecordFromTranscriptFail(error);
    }

    // Create Record with available Data
    try {
      newRecordId = await createLogbookRecord({
        ...newRecord,
        userName: currentUser?.name // TODO Deprecate
      });

      setLogbookRecordId(newRecordId);

      if (newRecordId) {
        toast.success('Record saved to Logbook!');

        createRecordTrackingEvent('Record - Create - Success', {
          audio: true,
          logbookRecordId: newRecordId
        });

        dispatch(getLogbook({ id: logbook?.id, orgId, logbookHours: true }));

        dispatch(getLogbookRecords({
          logbookId: logbook?.id,
          limit: 1
        })).then(() => {
          setLoading(false);

          if (onSuccessCallback && typeof onSuccessCallback === 'function') {
            onSuccessCallback();
          }
        });
      }
    } catch (error) {
      createLogbookRecordFail(error);
    }

    if (!newRecordId) {
      createLogbookRecordFail();
      return;
    }

    createResource({
      name: `Recording ${dayMdyDate(new Date().toISOString())}`,
      url: awsAudioFileUrl,
      size,
      duration,
      orgId,
      userId: currentUser?.id,
      userName: currentUser?.name,
      type,
      parentType: 'logbookRecords',
      parentId: newRecordId
    }).then((responseResource) => {
      updateLogbookRecord({
        resourceIds: [responseResource?.id]
      }, newRecordId);
    });
  }, [
    apprenticeshipId,
    audioNotes,
    createLogbookRecordFail,
    createRecordTrackingEvent,
    currentUser?.id,
    currentUser?.name,
    dispatch,
    logbook?.apprenticeshipId,
    logbook?.employerId,
    logbook?.id,
    logbook?.locationId,
    logbookRecordFromTranscriptFail,
    onSuccessCallback,
    orgId,
    setLoading,
    transcribeFail,
    uploadAudioFail,
    userId
  ]);

  useEffect(() => {
    if (!logbookRecordId) {
      if (Array.isArray(audioNotes) && audioNotes.length > 0) {
        if (recordState === RecordState.STOP && !loading) {
          setLoading(true);
          transcribeCreateRecord();
        }
      }
    }
  }, [
    audioNotes,
    logbookRecordId,
    recordState,
    loading,
    transcribeCreateRecord,
    setLoading
  ]);

  useEffect(() => {
    if (transcript && currentIndex < transcript.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + transcript[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 50);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, transcript]);

  return (
    <>
      {recordState === RecordState.NONE && audioNotes.length === 0 && (
        <div
          className="welcomeMessage animateIn"
        >
          <div
            className="Box mt-3 p-3 rounded shadow flex-column d-flex justify-content-center"
            style={{
              border: '1px solid #1d1d1d'
            }}
          >
            <div
              className='Box mb-3 px-2 rounded shadow'
              style={{ minHeight: 'auto' }}
            >
              <div className="BoxSubText">
                {greeting}, {firstName}
              </div>
              <div
                className="BoxText"
                style={{ minHeight: 'auto' }}
              >
                I'm VELA.
              </div>
            </div>

            <div className='velaInstructions'>
              <div
                className='Box p-2 rounded shadow mb-0'
                style={{ minHeight: 'auto' }}
              >
                <div className="BoxSubText" style={{ color: '#e2e2e2' }}>
                  I understand natural language, like...
                </div>
              </div>

              <div
                className='ColoredBox px-3 py-4 rounded shadow my-2'
                style={{ minHeight: 'auto' }}
              >
                <div
                  className='BoxText d-flex align-items-center text-center'
                  style={{ minHeight: 'auto' }}
                >
                  <div className='w-100'>
                    <span className="TextYellow">Today</span> I worked on the <span className="TextPurple">CNC machine</span> for <span className="TextBlue">three hours</span>.
                  </div>
                </div>
              </div>

              <div
                className='Box p-2 rounded shadow mb-0'
                style={{ minHeight: 'auto' }}
              >
                <div className="BoxSubText">
                  <span className="TextYellow">Date</span> = {mdySimple(new Date())}
                </div>
                <div className="BoxSubText">
                  <span className="TextPurple">Action</span> = Task or Machine you worked
                </div>
                <div className="BoxSubText">
                  <span className="TextBlue">Time</span> = 3 Hours
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {recordState === RecordState.STOP && loading && (
        <>
          {transcript ? (
            <div className="mt-3 welcomeMessage animateIn">
              <LogbookRecord
                key={mockRecord?.id}
                data={mockRecord}
                apprenticeshipCompetencies={competencies}
                badgeClassName="text-white"
              />
            </div>
          ) : (
            <>
              <div className="mt-3 welcomeMessage animateIn">
                <div
                  className="Box px-5 py-3 rounded shadow flex-column d-flex justify-content-center"
                  style={{
                    border: '1px solid #1d1d1d'
                  }}
                >
                  <div className='BoxSubText'>
                    I'm doing some heavy lifting...
                  </div>
                  <div className='BoxText'>
                    Analyzing audio for task, competencies and time.
                  </div>
                </div>
              </div>
              <div className="ColoredBox rounded shadow position-relative p-3 mt-3">
                <LoadingWave type="contained" />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RecordAudioForm;
