export const GMAIL_SEND = '/v1/external/gmail/send';
export const WEATHER_BASE_URL = '/v1/external/weather';
export const FIREBASE_STORE_COLLECTION = '/v1/external/firebase/store';
export const COS_OCCUPATIONS = '/v1/external/cos/occupations';
export const COS_OCCUPATION = '/v1/external/cos/occupation';

export const OPEN_API_CREATE_COMPLETION = '/v1/external/openai/create-completion';
export const OPEN_API_LOGBOOK_RECORD_FROM_TRANSCRIPT = '/v1/external/openai/logbook-record-from-transcript';
export const OPEN_API_TRANSCRIBE = '/v1/external/openai/transcribe';
export const OPEN_API_SUMMARIZE_DOC = '/v1/external/openai/summarizeDoc';

// Supabase
export const SUPABASE_BASE_URL = '/external/supabase';
export const SUPABASE_EMBED_DOC = '/v1/external/supabase/embedDoc';
export const SUPABASE_CONTENT_TYPES = [
  'materials',
  'projects', // Projects do NOT allow users to upload images/files in WYSIWYG
  'notifications',
  'resourceGroup' // TODO change to "resourceGroups"
];

export const SLACK_REPORT_ISSUE_CHANNEL = '/v1/external/slack/reportIssue';
// AWS
export const API_AWS_UPLOAD = '/v1/external/aws/upload';
// Pagination
export const DEFAULT_PAGINATION_LIMIT = 20;
export const RECORDS_PAGINATION_LIMIT = 50;
