import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useFlagsmith, useFlagsmithLoading } from 'flagsmith/react';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import heapAnalytics from '../../utils/heapAnalytics';
// Global Components
import TopNav from '../TopNav';
import Login from '../auth/Login';
import SSO from '../auth/SSO';
import Modals from '../Modals';
// Routes
import Slash from '../../routes/Slash';
import Logbook from '../../routes/Logbook';
import Logbooks from '../../routes/Logbooks';
import Records from '../../routes/Records';
import Materials from '../../routes/Materials';
import Dashboard from '../../routes/Dashboard';
import SideSheets from '../SideSheets';
// Styles
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../styles/colors.css';
import '../../styles/cards.css';
import '../../styles/buttons.css';
import '../../styles/forms.css';
import '../../styles/tables.css';
import '../../styles/navs.css';
import '../../styles/modals.css';
import '../../styles/print.css';
import '../../styles/manageContent.css';
import '../../styles/timeline.css';
import '../../styles/themeDark.css';
import './style.css';
import Progress from '../../routes/Progress';

const App = () => {
  const { getFlags } = useFlagsmith();
  const { isLoading } = useFlagsmithLoading();
  const searchParams = new URLSearchParams(window.location.search);
  const postNewRecord = searchParams.get('postNewRecord');

  useEffect(() => {
    heapAnalytics.initialize();
  }, []);

  // FLAGS & REMOTE CONFIG
  useEffect(() => {
    if (!isLoading || postNewRecord === 'true') {
      getFlags();
    }
  }, [getFlags, isLoading, postNewRecord]);
  // /FLAGS & REMOTE CONFIG

  return (
    <Router>
      <>
        <TopNav />
        {/* <div className="container-fluid px-0 px-sm-3"> */}
        <div className="container">
          <Switch>
            <Route exact path={ROUTES.SSO} component={SSO} />
            <Route exact path={ROUTES.LOGIN_ORG} component={Login} />
            <Route exact path={ROUTES.LOGIN_ORG} component={Login} />
            <Route path={ROUTES.LOGIN} component={Login} />
            <Route path={ROUTES.DASHBOARD} component={Dashboard} />
            <Route path={ROUTES.LOGBOOK_PROGRESS} component={Progress} />
            <Route path={ROUTES.LOGBOOK_RECORDS} component={Records} />
            <Route path={ROUTES.RECORDS} component={Records} /> {/* TODO: Deprecate */}
            <Route path={ROUTES.LOGBOOK} component={Logbook} />
            <Route path={ROUTES.LOGBOOKS} component={Logbooks} />
            <Route path={ROUTES.MATERIAL} component={Logbook} />
            <Route path={ROUTES.MATERIALS} component={Materials} />
            <Route path={ROUTES.PROGRAMS} component={Slash} /> {/* Deprecated */}
            <Route path={ROUTES.PROGRAM} component={Slash} /> {/* Deprecated */}
            <Route exact path={ROUTES.SLASH} component={Slash} />
          </Switch>
        </div>
        <SideSheets />
        <Modals />
        <ToastContainer
          className="text-center"
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </>
    </Router>
  );
};

export default withAuthentication(App);
