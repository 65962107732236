import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowCircleRight, FaListAlt } from 'react-icons/fa';
import { canAccessTurbineAdmin } from '@apprentage/utils';
import { LOGBOOKS, MATERIALS } from '../constants/routes';
import {
  // IMAGE_CARD_DECISION,
  IMAGE_CARD_KNOWLEDGE_TRANSFER,
  IMAGE_CARD_LOGBOOKS,
  IMAGE_CARD_STANDUP
} from '../constants/assets';
import DashboardCard from './DashboardCard';
import { canAccessLogbook } from '../services/currentUser';
import { TURBINE_ADMIN } from '../constants/urls';
import { getApprenticeships } from '../actions/Apprenticeships';

const WorkflowCards = ({
  className,
  onClick,
  showMyMaterialsLink
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeships = useSelector((state) => state.apprenticeships);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const role = currentUser?.role || [];
  const apprenticeshipIds = currentUser?.apprenticeshipIds || null;
  const hasTrainingPrograms = useMemo(() => {
    return apprenticeshipIds && Array.isArray(apprenticeships?.list) && apprenticeships?.list.length > 0;
  }, [apprenticeshipIds, apprenticeships?.list]);

  useEffect(() => {
    if (Array.isArray(apprenticeshipIds) && apprenticeshipIds.length > 0) {
      dispatch(getApprenticeships({ ids: apprenticeshipIds, orgId }));
    }
  }, [apprenticeshipIds, dispatch, orgId]);

  if (!currentUser?.id || !organization?.id) {
    return null;
  }

  return (
    <>
      {organization?.enableWorkforceSuite && (
        <>
          {canAccessLogbook(role) && hasTrainingPrograms && (
            <DashboardCard
              title="Logbooks"
              subTitle="Record tasks for Training Programs."
              onClick={() => {
                history.push(LOGBOOKS);

                if (onClick) {
                  onClick();
                }
              }}
              className='mb-3'
              imgUrl={IMAGE_CARD_LOGBOOKS}
              imgPosition="top"
            />
          )}

          {canAccessTurbineAdmin(role) && (
            <DashboardCard
              href={TURBINE_ADMIN}
              className='mb-3'
              externalLink
              title="Review Records"
              subTitle="Review Trainee Logbook Records"
              titleIconComponent={() => (
                <FaListAlt size={25} className='mr-2 text-primary opacity-50' />
              )}
            />
          )}
        </>
      )}

      {organization?.enableVelaMaterials && (
        <div className={className}>
          <DashboardCard
            title="Daily Check-In"
            subTitle="Record progress, plans & roadblocks."
            onClick={() => {
              history.push(`${MATERIALS}?postNewMaterial=true&workflow=standup`);

              if (onClick) {
                onClick();
              }
            }}
            className='mb-3'
            imgUrl={IMAGE_CARD_STANDUP}
            imgPosition="top"
          />

          {/* <DashboardCard
            title="Decision"
            subTitle="Document a decision for posterity."
            onClick={() => {
              history.push(`${MATERIALS}?postNewMaterial=true&workflow=decision`);

              if (onClick) {
                onClick();
              }
            }}
            className='mb-3'
            imgUrl={IMAGE_CARD_DECISION}
            imgPosition="top"
          /> */}

          <DashboardCard
            title="Knowledge Capture"
            subTitle="Share ideas, workflows and processes."
            className='mb-3'
            onClick={() => {
              history.push(`${MATERIALS}?postNewMaterial=true&workflow=knowledge`);
              if (onClick) {
                onClick();
              }
            }}
            imgUrl={IMAGE_CARD_KNOWLEDGE_TRANSFER}
            imgPosition="center"
          />

          {showMyMaterialsLink && (
            <div
              className='rounded py-3 text-center mt-3'
              style={{ backgroundColor: 'rgb(32, 32, 32)' }}
            >
              <button
                onClick={() => {
                  history.push(MATERIALS);
                  if (onClick) {
                    onClick();
                  }
                }}
                type='button'
                className="btn-lg btn-link text-white"
              >
                <span className='d-flex align-items-center'>
                  <span className='mr-2'>
                    My Resources
                  </span>
                  <FaArrowCircleRight />
                </span>
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default WorkflowCards;
