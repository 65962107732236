import React from 'react';
import { FaRegClock } from 'react-icons/fa6';

const TimeBadge = ({
  duration,
  className = 'ml-2 h6 m-0',
  badgeClassName = 'badge-info',
  ...props
}) => {
  return (
    <span
      className={className}
      {...props}
    >
      <span
        className={`d-inline-flex align-items-center badge ${badgeClassName}`}
      >
        <FaRegClock className='mr-1' />
        <span>{duration}</span>
      </span>
    </span>
  );
};

export default TimeBadge;
