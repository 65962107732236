import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { percentComplete } from '@apprentage/utils';
import { fetchLogbookRecords } from '../../services/logbookRecords';
import { LOGBOOK_PROGRESS, LOGBOOK_RECORDS } from '../../constants/routes';
import './style.css';

const LogbookTabs = ({
  active,
  className = '',
  // onClick,
  ojtHours
}) => {
  // redux
  const organization = useSelector((state) => state.organization);
  const logbook = useSelector((state) => state.logbook);
  const logbookRecords = useSelector((state) => state.logbookRecords);

  // Redux
  const logbookHours = useSelector((state) => state.logbookHours);
  // Calculations
  const totalOjtHours = parseInt(ojtHours, 10);

  // Hours Approved
  const approvedValueNow = logbookHours.approvedHours;
  // const approvedPercent = percentComplete({
  //   current: approvedValueNow,
  //   total: totalOjtHours
  // });

  // Hours In Review
  const inReviewValueNow = logbookHours.totalHours;
  const inReviewPercent = percentComplete({
    current: inReviewValueNow - approvedValueNow,
    total: totalOjtHours
  });

  // Organization
  const orgId = organization?.id || '';

  useEffect(() => {
    if (logbook.fetched && logbook?.id) {
      fetchLogbookRecords({
        orgId,
        logbookId: logbook?.id,
        isApproved: null,
        count: true
      });
    }
  }, [logbook?.id, logbook.fetched, orgId]);

  return (

    <div
      className={`mt-3 ${className}`}
    >
      <ul
        className="nav nav-tabs"
      >
        <li className="nav-item">
          <Link
            className={`nav-link d-flex align-items-center px-3 h6 m-0 text-white ${active === 'records' ? 'active' : ''}`}
            to={`${LOGBOOK_RECORDS}?logbookId=${logbook?.id}&postNewRecord=true`}
          >
            <span className='d-flex align-items-center'>
              <span className=''>
                Records
              </span>
              <span className="ml-2 badge bg-white border text-dark">
                {logbookRecords?.pagination?.total}
              </span>
            </span>
          </Link>
        </li>

        <li className="nav-item ml-2">
          <Link
            className={`nav-link d-flex align-items-center px-3 h6 m-0 text-white ${active === 'progress' ? 'active' : ''}`}
            to={`${LOGBOOK_PROGRESS}?logbookId=${logbook?.id}&postNewRecord=true`}
          >
            <span className='d-flex align-items-center'>
              <span className=''>
                Progress
              </span>
              <span className="ml-2 badge bg-white border text-dark">
                {inReviewPercent}
              </span>
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default LogbookTabs;
