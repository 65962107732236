import React from 'react';
import { percentComplete } from '@apprentage/utils';
import { useSelector } from 'react-redux';
import { FaCircleCheck, FaClock } from 'react-icons/fa6';
import { FaCircle } from 'react-icons/fa';
import ProgressBars from '../../ProgressBars';
import { mdySimple } from '../../../utils/date';
import makeInitials from '../../../utils/makeInitials';

const CompetenceProgress = ({
  userTotalHours,
  totalHours,
  competencyId
}) => {
  // Redux
  const logbook = useSelector((state) => state.logbook);
  const logbookHours = useSelector((state) => state.logbookHours);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Apprenticeship
  const ojtHours = apprenticeship?.ojtHours || 0;
  // Logbook
  const completedCompetencies = logbook?.completedCompetencies || {};
  const isComplete = completedCompetencies[competencyId] !== undefined;

  const competencyHours = logbookHours?.competencyHours ? logbookHours?.competencyHours[competencyId] : 0;
  const compHrsApproved = logbookHours?.compHrsApproved ? logbookHours?.compHrsApproved[competencyId] : 0;
  const compHrsUnapproved = logbookHours?.compHrsUnapproved ? logbookHours?.compHrsUnapproved[competencyId] : 0;

  const completedComp = isComplete ? completedCompetencies[competencyId] : null;
  const hoursApproved = completedComp?.approvedHours;

  // Hours Approved
  const approvedPercent = percentComplete({
    current: compHrsApproved,
    total: totalHours
  });

  // Hours In Review
  const unapprovedPercent = percentComplete({
    current: compHrsUnapproved,
    total: userTotalHours || totalHours
  });

  return (
    <>
      <div className="d-flex justify-content-between mt-3 mb-2">
        {isComplete ? (
          <div className="small d-flex align-items-center">
            <span className='d-flex align-items-center'>
              <span>
                Approved Hours
              </span>
            </span>
            <span className='h6 mb-0 ml-1'>
              <span
                className='badge py-1 px-2 text-light bg-191d1f'
              >
                <span className='d-flex align-items-center'>
                  <FaClock className='text-keppel mr-2' />
                  <span>
                    {completedComp?.approvedHours || 0}
                  </span>
                </span>
              </span>
            </span>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className='d-flex align-items-center'>
                <span>
                  Hours
                </span>
              </span>
              <span className='h6 mb-0 ml-1'>
                <span
                  className='badge py-1 px-2 text-light bg-191d1f'
                >
                  <span className='d-flex align-items-center'>
                    <FaClock className='text-light mr-2' />
                    <span>
                      {competencyHours}
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
        )}
        <div className="small d-flex align-items-center">
          <span className='d-flex align-items-center'>
            <span>
              Target
            </span>
            <span className='d-none d-sm-block ml-1'>
              Hours
            </span>
          </span>

          <span className='h6 mb-0 ml-1'>
            <span
              className='badge py-1 px-2 text-light bg-191d1f'
            >
              <span className='d-flex align-items-center'>
                <span>
                  {userTotalHours !== undefined ? userTotalHours : totalHours}
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>

      {isComplete && (
        <div
          className="mt-2 text-nowrap text-light bg-191d1f px-2 py-1 rounded d-flex align-items-center justify-content-between"
        >
          <div className='d-flex align-items-center'>
            <FaCircleCheck className='text-keppel' />
            <div className='ml-2'>
              <span>
                Approved
              </span>
              {completedComp?.approvedByName && (
                <span className='ml-1 d-inline-block d-sm-none'>by <strong>{makeInitials(completedComp?.approvedByName)}</strong></span>
              )}
              {completedComp?.approvedByName && (
                <span className='ml-1 d-none d-sm-inline-block'>by <strong>{completedComp?.approvedByName}</strong></span>
              )}
            </div>
          </div>
          <div className='small'>
            {mdySimple(completedComp?.approvedAt, '-')}
          </div>
        </div>
      )}

      {!isComplete && (userTotalHours === undefined || (userTotalHours !== undefined && userTotalHours > 0)) && (
        <div className="mt-2">
          <ProgressBars
            size="md"
            className='bg-191d1f'
            data={[
              {
                className: 'bg-keppel',
                dataTip: 'Approved Hours',
                valueNow: hoursApproved,
                // valueMin: 0,
                style: {
                  width: compHrsApproved !== 0 && parseFloat(compHrsApproved) <= 1 ? '1%' : approvedPercent
                },
                text: ''
              },
              {
                className: 'progress-bar bg-primary',
                dataTip: 'Unapproved Hours',
                valueNow: compHrsUnapproved,
                // valueMin: 0,
                style: {
                  width: compHrsUnapproved !== 0 && parseFloat(unapprovedPercent) <= 1 ? '1%' : unapprovedPercent
                },
                text: '',
                valueMax: ojtHours
              }
            ]}
          />
          <div className='mt-2 d-flex align-items-center justify-content-between'>

            <div className="small d-flex align-items-center">

              <span>
                Approved
              </span>

              <span className='h6 mb-0 ml-1'>
                <span
                  className='badge py-1 px-2 text-light bg-191d1f'
                >
                  <span className='d-flex align-items-center'>
                    <FaCircle className='text-keppel mr-2' />
                    <span>
                      {compHrsApproved || 0}
                    </span>
                  </span>
                </span>
              </span>
            </div>

            <div className='small d-flex align-items-center'>

              <span>
                Unapproved
              </span>

              <span className='h6 mb-0 ml-1'>
                <span
                  className='badge py-1 px-2 text-light bg-191d1f'
                >
                  <span className='d-flex align-items-center'>
                    <FaCircle className='text-primary mr-2' />
                    <span>
                      {compHrsUnapproved || 0}
                    </span>
                  </span>
                </span>
              </span>
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default CompetenceProgress;
