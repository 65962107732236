import React from 'react';
import { FaBook } from 'react-icons/fa6';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import OrgLocation from '../../OrgLocation';
import { LOGBOOK } from '../../../constants/routes';

const ProgramContainer = ({
  className = '',
  apprenticeshipId,
  apprenticeshipTitle,
  logbookId,
  logbookLocationId,
  children
}) => {
  // const shortLogbookId = logbookId ? logbookId.substring(logbookId.length - 12) : null;
  return (
    <div
      key={apprenticeshipId}
      className={`Row ${className}`}
      style={{
        minHeight: 'auto'
      }}
    >
      {logbookId && (
        <div
          className='RowHeader d-flex align-items-center justify-content-between pl-3 pr-2 py-2'
        >
          <span className="d-flex align-items-center">
            <FaBook size={15} />
            <span className='h6 m-0 ml-2'>
              Logbook
            </span>
          </span>
          {/* <span
            className='ml-2 text-muted small'
          >
            ID #{shortLogbookId}
          </span> */}
          <Link
            className='btn btn-sm btn-primary'
            to={`${LOGBOOK}?logbookId=${logbookId}&postNewRecord=true`}
          >
            <span className='d-flex align-items-center'>
              <FaPlus size={15} />
              <span className='ml-2'>
                New Record
              </span>
            </span>
          </Link>
        </div>
      )}

      <div
        className='p-3 h-100 px-1'
      >

        <div className='d-flex align-items-center justify-content-between '>
          <div
            className='BoxText h-100 small'
          >
            {apprenticeshipTitle}
          </div>
        </div>

        {logbookLocationId && (
          <div className='bg-light px-3 py-1 d-inline-block small rounded mt-2'>
            <OrgLocation
              locationId={logbookLocationId}
            />
          </div>
        )}

      </div>

      {children && (
        <div
          className='RowFooter border-top d-flex align-items-center justify-content-between p-3'
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default ProgramContainer;
