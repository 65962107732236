import React, { useState, useEffect } from 'react';
import './style.css';

const Stopwatch = ({
  theme,
  simple,
  isRunning = false,
  showButtons = false,
  setIsRunning,
  stop
}) => {
  // state to store time
  const [time, setTime] = useState(0);

  useEffect(() => {
    let intervalId;
    if (time === 284400) { // (5min (300000) - action/redux delays)
      clearInterval(intervalId);
      stop();
    }
    if (isRunning) {
      // setting time from 0 to 1 every 10 millisecond using javascript setInterval method
      intervalId = setInterval(() => setTime(time + 50), 50);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  // Hours calculation
  // const hours = Math.floor(time / 360000);

  // Minutes calculation
  // const minutes = Math.floor((time % 360000) / 6000);

  // Seconds calculation
  // const seconds = Math.floor((time % 6000) / 100);

  // Milliseconds calculation
  const milliseconds = time % 100;

  // NEW //
  const sec = Math.floor(time / 1000);
  const min = Math.floor(sec / 60);
  const hour = Math.floor(min / 60);
  // const millis = (currentTime % 1000).toString().padStart(3, "0")
  const seconds = (sec % 60).toString().padStart(2, '0');
  const minutes = (min % 60).toString().padStart(2, '0');
  const hours = (hour % 24).toString().padStart(2, '0');
  //  -  //

  // Method to start and stop timer
  const startAndStop = () => {
    const runningState = !isRunning;

    setIsRunning(runningState);
  };

  // Method to reset timer back to 0
  const reset = () => {
    setTime(0);
  };
  return (
    <div className="stopwatch-container">
      <div
        className='d-flex align-items-center justify-content-center'
      >
        <div className='mr-2 h-100 d-flex align-items-center'>
          <div
            className={`blinker ${isRunning ? '' : 'isPaused'}`}
          />
        </div>
        {!simple && (
          <p className="stopwatch-time">
            {hours}:{minutes.toString().padStart(2, '0')}:
            {seconds.toString().padStart(2, '0')}:
            {milliseconds.toString().padStart(2, '0')}
          </p>
        )}
        {simple && (
          <p className="stopwatch-time m-0">
            {minutes.toString()}:
            {seconds.toString().padStart(2, '0')}
          </p>
        )}
      </div>
      <div className="mt-1 mb-3 w-100 text-center">
        <div className={`${theme === 'dark' ? 'text-light' : ''}`}>
          Recording Audio
        </div>
        <div className='mt-1'>
          <div className='badge badge-warning'>5 min max.</div>
        </div>
      </div>

      {showButtons && (
        <div className="stopwatch-buttons">
          <button
            className="stopwatch-button"
            onClick={startAndStop}
            type="button"
          >
            {isRunning ? 'Stop' : 'Start'}
          </button>
          <button
            className="stopwatch-button"
            onClick={reset}
            type="button"
          >
            Reset
          </button>
        </div>
      )}
    </div>
  );
};

export default Stopwatch;
