import React from 'react';
import Skeleton from 'react-loading-skeleton';

const TableLoading = ({ className = '', skeletonClassName = '' }) => {
  return (
    <table
      className={`table table-bordered m-0 ${className}`}
    >
      <thead>
        <tr>
          <th>
            <Skeleton className={skeletonClassName} />
          </th>
        </tr>
        <tr>
          <th>
            <Skeleton className={skeletonClassName} />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Skeleton className={skeletonClassName} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton className={skeletonClassName} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableLoading;
