import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { roleReadable } from '@apprentage/utils';
import { withAuthorization } from '../../components/Session';
import Loading from '../../components/Loading';
import PersonActivityCard from '../../components/PersonActivityCard';
import WorkflowCards from '../../components/WorkflowsCards';

const Dashboard = () => {
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);

  if (!currentUser?.id || !organization?.id) {
    return <Loading />;
  }

  return (
    <div style={{ maxWidth: '345px', margin: 'auto' }}>
      <div
        className="row justify-content-center mt-3"
      >
        <div className="col-12 mb-3">
          <PersonActivityCard
            name={currentUser?.name}
            personRole={currentUser?.title || roleReadable(currentUser?.role)}
            profilePicture={currentUser?.profilePicture || DEFAULT_PROFILE_PIC}
          />
        </div>
        <div
          className="col-12 mb-3"
        >
          <WorkflowCards showMyMaterialsLink />
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(Dashboard);
