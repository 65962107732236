import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFlags, useFlagsmith, useFlagsmithLoading } from 'flagsmith/react';
import { FaLifeRing, FaListAlt } from 'react-icons/fa';
import {
  FLAG_APP_VELA,
  FLAG_MAINTENANCE_MODE,
  NOTICE_MAINTENANCE_MODE_BODY,
  NOTICE_MAINTENANCE_MODE_TITLE
} from '../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../actions/FeatureFlags';
import {
  DEFAULT_PROFILE_PIC, VELA_ICON, VELA_LOGO
} from '../../constants/assets';
import { canAccessLogbook, canAccessVelaMaterials } from '../../services/currentUser';
import { logout } from '../../actions/Session';
import { resetUser } from '../../actions/Users';
import { openReportIssueModal, setCurrentModal } from '../../actions/Modals';
import { logUserOut, redirectToTurbineApp } from '../../services/auth';
import { PLATFORM_NAME } from '../../constants/globals';
import { DASHBOARD, LOGBOOKS } from '../../constants/routes';
import { TURBINE, VELA_DOCS } from '../../constants/urls';
import { usingLatestAppVersion } from '../../services/organizations';
import { MODAL_KEY_RELOAD } from '../../constants/modals';
import UserDetails from './UserDetails';
import WorkflowCards from '../WorkflowsCards';
import './fab.css';
import './style.css';

const TopNav = () => {
  const dispatch = useDispatch();
  // Flagsmith
  const flags = useFlags([FLAG_APP_VELA, FLAG_MAINTENANCE_MODE]);
  const { getFlags } = useFlagsmith();
  const { isLoading } = useFlagsmithLoading();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || '';
  const enableVelaMaterials = organization?.enableVelaMaterials || false;
  const orgLogo = organization?.orgLogo || '';
  const orgName = organization?.name || '';
  // Current User
  const role = currentUser?.role || [];
  const userName = currentUser?.name || '';
  const userEmail = currentUser?.email || '';
  const testUser = currentUser?.testUser || null;
  const profilePicture = currentUser?.profilePicture || DEFAULT_PROFILE_PIC;
  // Search Params
  const searchParams = new URLSearchParams(window.location.search);
  const postNewRecord = searchParams.get('postNewRecord');
  // Local State
  const [showMenu, setShowMenu] = useState(false);

  // FLAGS & REMOTE CONFIG
  useEffect(() => {
    if (!isLoading) {
      getFlags();
    }

    if (flags?.app_vela?.enabled && flags?.app_vela?.value !== undefined) {
      if (!usingLatestAppVersion(flags?.app_vela?.value)) {
        dispatch(setCurrentModal({
          key: MODAL_KEY_RELOAD,
          data: {
            appVersion: flags?.app_vela?.value
          }
        }));
      }
    }

    if (flags?.maintenance_mode?.enabled && flags?.maintenance_mode?.value !== undefined) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_MAINTENANCE_MODE_TITLE,
        modalBody: NOTICE_MAINTENANCE_MODE_BODY,
        preventClose: true
      }));
    }
  }, [getFlags, isLoading, postNewRecord, flags?.maintenance_mode, flags?.app_vela, dispatch]);
  // /FLAGS & REMOTE CONFIG

  if (!orgId || !currentUser?.id) {
    // TODO Skeleton Loading
    return null;
  }

  const handleLogout = () => {
    logUserOut().then(() => {
      dispatch(logout());
      dispatch(resetUser());
    });
  };

  return (
    <>
      <div id="TopNav">
        <div
          className='d-flex align-items-center justify-content-between p-2'
          style={{
            backgroundColor: '#202020',
            color: 'white',
            fontWeight: 'bold'
          }}
        >
          {canAccessVelaMaterials(role, enableVelaMaterials) ? (
            <button
              onClick={() => {
                setShowMenu(!showMenu);
              }}
              type="button"
              className={`fab-btn ml-2 mr-3 ${showMenu ? 'active' : ''}`}
              aria-label='Floating Action Button'
            />
          ) : (
            <div className='ml-2 mr-3' style={{ minWidth: '30px' }} />
          )}

          <Link
            to={DASHBOARD}
            className="btn-link"
          >
            <img
              src={VELA_LOGO}
              alt={PLATFORM_NAME}
              style={{
                height: '18px'
              }}
            />
          </Link>

          <div
            className="d-flex align-items-center"
            style={{
              right: '10px'
            }}
          >
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <div
                  className="top-nav-trigger nav-link text-nowrap"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="user-profile">
                    <img src={profilePicture} alt={userName} />
                  </span>
                </div>
                <div
                  className="dropdown-menu my-0 py-0 dropdown-menu-right"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <UserDetails>
                    {!testUser && (
                      <a
                        className="btn btn-sm btn-white border mt-2"
                        href={redirectToTurbineApp(`${TURBINE}/profile`)}
                        title="My Profile"
                      >
                        My Profile
                      </a>
                    )}
                  </UserDetails>

                  <div className='border-top border-bottom d-flex justify-content-between p-2'>
                    <div>
                      <span className="badge mb-1">Sponsor</span>
                    </div>
                    <img
                      src={orgLogo}
                      // className={className}
                      alt={orgName}
                      style={{
                        height: '30px'
                      }}
                    />
                    {/* <OrgLogo
                    className="mr-1"
                    height={20}
                  /> */}
                  </div>

                  {organization?.enableWorkforceSuite && canAccessLogbook(role) && (
                    <Link
                      to={LOGBOOKS}
                      className="dropdown-item border-bottom py-3"
                      data-cy="topNavLinkLogbooks"
                    >
                      <span className='d-flex align-items-center'>
                        <FaListAlt />
                        <span className='ml-2'>
                          Logbooks
                        </span>
                      </span>
                    </Link>
                  )}

                  <button
                    className="dropdown-item py-2"
                    onClick={() => {
                      dispatch(openReportIssueModal({
                        fromName: userName,
                        replyTo: userEmail,
                        orgName
                      }));
                    }}
                    type="button"
                  >
                    <span className='d-flex align-items-center'>
                      <FaLifeRing />
                      <span className='ml-2'>
                        Report Issue
                      </span>
                    </span>
                  </button>

                  <a
                    className="dropdown-item border-top py-2 d-flex align-items-center"
                    href={VELA_DOCS}
                    title="VELA Documentation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={VELA_ICON}
                      style={{
                        height: '18px'
                      }}
                      className='mr-2'
                      alt="White V in a Black Box"
                    />
                    <span>
                      Documentation
                    </span>
                  </a>

                  <div
                    className="card-footer d-flex align-items-center justify-content-between py-2"
                  >
                    <div>
                      <div className="small text-muted font-weight-bold">
                        VELA
                      </div>
                      <div className="text-muted small">
                        <span className="text-capitalize">Edition</span> v{flags?.app_vela?.value || 0}
                      </div>
                    </div>
                  </div>

                  <button
                    className="dropdown-item border-top py-2"
                    onClick={handleLogout}
                    type="button"
                  >
                    <i className="fas fa-sign-out-alt text-danger mr-1" /> Sign out
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className={`fab-btn-bkg ${showMenu ? 'active' : ''}`}
        onClick={() => {
          setShowMenu(false);
        }}
        aria-hidden
      />
      <div className={`fab-container ${showMenu ? 'active' : ''}`}>

        <div className={`fab-menu p-3 ${showMenu ? 'active' : ''}`}>
          <div className="row justify-content-center">
            <div
              className="col-12"
            >
              <WorkflowCards
                onClick={() => {
                  setShowMenu(false);
                }}
                showMyMaterialsLink
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNav;
