export const PLATFORM_NAME = 'VELA';
export const TURBINE_ADMIN_EMAIL = 'hello@turbineworkforce.com';
export const TURBINE_ADMIN_BCC_EMAIL = 'alan@turbineworkforce.com,josh@turbineworkforce.com';
export const CLASS_COLOR_DEFAULT = '#b3bac5';
export const TINYMCE_KEY = process.env.REACT_APP_TINYMCE;
// INTEGRATIONS
export const MICROSOFT_TEAMS_CHAT_LINK_BASE = 'https://teams.microsoft.com/l/chat/0/0?users=';

export const audioMimeTypeExtMap = {
  'audio/wav': '.wav',
  'audio/mpeg': '.mp3',
  'audio/ogg': '.oga'
};
