import React from 'react';
import ReactTooltip from 'react-tooltip';
import List from './List';

const UserCompetenciesList = ({
  list = []
  // competencyGroupId
}) => {
  if (!list.length) {
    return null;
  }

  return (
    <div className="apprenticeship-competencies-list">
      <List
        data={list}
      />
      <ReactTooltip html id="UserCompetenciesList" />
    </div>
  );
};

export default UserCompetenciesList;
