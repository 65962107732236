import React from 'react';
import { formatHours } from '@apprentage/utils';
import Skeleton from 'react-loading-skeleton';
import { mdySimple } from '../../utils/date';
import TimeBadge from '../TimeBadge';
import Transcript from './Transcript';
import CompetenciesCollapse from './CompetenciesCollapse';

const LogbookRecord = ({
  data,
  apprenticeshipCompetencies,
  badgeClassName = ''
}) => {
  return (
    <div className="ColoredBox rounded shadow position-relative p-3 mb-3">
      <div className="d-flex align-items-center justify-content-between mb-3">
        {data?.date ? (
          <div className='BoxSubText'>
            {mdySimple(data?.date)}
          </div>
        ) : (
          <div className='d-flex align-items-center'>
            <Skeleton style={{ width: '30px' }} baseColor='#222' />
          </div>
        )}

        <TimeBadge
          duration={formatHours(data?.hours ? data?.hours.toFixed(2) : 0)}
          badgeClassName={badgeClassName}
        />
      </div>
      <div
        className="BoxText text-center"
        style={{
          fontSize: '1rem'
        }}
      >
        <Transcript text={data?.transcript} />
      </div>

      <CompetenciesCollapse
        competencyIds={data?.competencyIds}
        competencies={apprenticeshipCompetencies}
      />
    </div>
  );
};

export default LogbookRecord;
