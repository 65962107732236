import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { withAuthorization } from '../../components/Session';
import { SLASH } from '../../constants/routes';
import { errorLogbookNotFound, getLogbook, resetLogbook } from '../../actions/Logbooks';
import { getApprenticeship, resetApprenticeship } from '../../actions/Apprenticeships';
import { canAccessLogbook } from '../../services/currentUser';
import { fetchLogbookHours } from '../../services/logbooks';
import Loading from '../../components/Loading';
import ProgramContainer from '../../components/Lists/ProgramsList/ProgramContainer';
import LogbookProgress from '../../components/LogbookProgress';
import LogbookNotFound from '../../components/LogbookNotFound';
import LogbookView from '../../components/LogbookView';
import LogbookTabs from '../../components/Tabs/LogbookTabs';

const Competencies = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbook = useSelector((state) => state.logbook);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  // Current User
  const userId = currentUser?.id || '';
  const role = currentUser?.role || [];
  const apprenticeshipIds = currentUser?.apprenticeshipIds || null;
  // Params
  const searchParams = new URLSearchParams(window.location.search);
  const apprenticeshipId = searchParams.get('apprenticeshipId');
  const logbookId = searchParams.get('logbookId');
  // Organization
  const orgId = organization?.id || '';

  useEffect(() => {
    if (logbookId) {
      fetchLogbookHours({
        orgId,
        logbookId,
        locationId: logbook?.locationId,
        apprenticeshipId,
        showCompHours: true
      });

      dispatch(getLogbook({
        id: logbookId,
        orgId,
        logbookHours: true,
        logbookCompHours: true
      }))
        .then((response) => {
          if (response?.orgId !== currentUser?.orgId) {
            toast.error('Logbook Organization Access Denied ', { autoClose: false });
            history.replace(SLASH);
            return;
          }

          if (response?.userId !== currentUser?.id) {
            toast.error('Logbook User Access Denied', { autoClose: false });
            history.replace(SLASH);
            return;
          }

          dispatch(getApprenticeship(response?.apprenticeshipId));
        })
        .catch((error) => {
          console.error(error);
          dispatch(errorLogbookNotFound({
            logbookId,
            orgId: organization?.id,
            orgName: organization?.name,
            userId,
            userName: currentUser.name,
            userEmail: currentUser?.email,
            userLocationId: currentUser?.locationId
          }));
        });
    }

    return function cleanup() {
      dispatch(resetApprenticeship());
      dispatch(resetLogbook());
    };
  }, []);

  if (!currentUser.id || !organization.id) {
    return (
      <Loading
        className='position-relative'
        style={{ background: '#121212' }}
      />
    );
  }

  if (!apprenticeshipIds || (Array.isArray(apprenticeshipIds) && apprenticeshipIds.length === 0)) {
    return <Redirect to={SLASH} />;
  }

  if (!canAccessLogbook(role)) {
    return <Redirect to={SLASH} />;
  }

  if (logbookId && !logbook.fetched && !logbook.id) {
    return (
      <Loading
        className='position-relative'
        style={{ background: '#121212' }}
        text="Loading Logbook..."
      />
    );
  }

  if (!logbookId || (logbook.fetched && !logbook.id)) {
    return <LogbookNotFound />;
  }

  return (
    <div>
      {logbook?.id && (
        <ProgramContainer
          className="mt-3"
          apprenticeshipId={apprenticeship?.id}
          apprenticeshipTitle={apprenticeship?.title}
          logbookId={logbookId}
        // logbookLocationId={logbook?.employerId}
        >
          <LogbookProgress
            className='w-100'
            records={logbookRecords.list}
            ojtHours={apprenticeship?.ojtHours}
          />
        </ProgramContainer>
      )}

      <LogbookTabs
        active="progress"
        ojtHours={apprenticeship?.ojtHours}
      />

      {logbook?.id && (
        <div
          className='p-3 theme-dark table-dark bg-343a40 position-relative'
        >
          <LogbookView className='theme-dark' ariaExpandedFirstItem />
        </div>
      )}
    </div>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(Competencies);
