import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CompetenceProgress from '../CompetenceProgress';

const DescriptionCell = ({
  value: description,
  row
}) => {
  // Redux
  const logbookHours = useSelector((state) => state.logbookHours);
  const userCompetencies = useSelector((state) => state.userCompetencies);
  // Apprenticeship User
  const userCompetenciesHash = userCompetencies?.hash || null;

  const competencyId = row?.original?.id || null;
  const totalHours = row?.original?.totalHours || null;

  const userTotalHours = userCompetenciesHash ? userCompetenciesHash[row?.original?.id]?.totalHours : undefined;

  const hoursEarned = useMemo(() => {
    if (logbookHours?.competencyHours) {
      if (logbookHours?.competencyHours[competencyId] !== undefined) {
        const hours = logbookHours?.competencyHours[competencyId];
        const strValue = `${hours}`;

        return strValue.includes('.') ? hours.toFixed(2) : hours;
      }
    }

    return 0;
  }, [competencyId, logbookHours?.competencyHours]);

  return (
    <div className='pt-3 pb-2'>
      <div>
        {description}
      </div>

      <CompetenceProgress
        competencyId={competencyId}
        userTotalHours={userTotalHours}
        totalHours={totalHours}
        hoursEarned={hoursEarned}
      />
    </div>
  );
};

export default DescriptionCell;
