import React from 'react';
import Table from './Table';
import DescriptionCell from './DescriptionCell';

const List = ({
  data
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Competencies',
        accessor: 'description',
        disableSortBy: true,
        Cell: DescriptionCell
      }
      // {
      //   Header: 'Earned / Total',
      //   accessor: 'totalHours',
      //   disableSortBy: true,
      //   Cell: HoursCell
      // },
      // {
      //   Header: 'Level',
      //   accessor: 'id',
      //   disableSortBy: true,
      //   Cell: CompetenceLevelCell
      // },
      // {
      //   Header: 'Date Completed',
      //   accessor: 'createdAt',
      //   disableSortBy: true,
      //   Cell: DateCompletedCell
      // }
    ],
    []
  );

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">No Work Processes.</div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
    />
  );
};

export default List;
