import { generateHashFromArray } from '@apprentage/utils';
import {
  SET_USER_COMPETENCIES,
  RESET_USER_COMPETENCIES
} from '../../actions/types';

const initialState = {
  list: null,
  pagination: null,
  hash: null,
  fetched: false
};

export default function userCompetencies(state = initialState, action) {
  switch (action.type) {
    case SET_USER_COMPETENCIES:
      return {
        ...state,
        list: action.list,
        pagination: action.pagination,
        hash: generateHashFromArray(action.list, 'compId'),
        fetched: true
      };
    case RESET_USER_COMPETENCIES:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
