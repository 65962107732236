import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFlags, useFlagsmith } from 'flagsmith/react';
import { withAuthorization } from '../../components/Session';
import { SLASH } from '../../constants/routes';
import { errorLogbookNotFound, getLogbook, resetLogbook } from '../../actions/Logbooks';
import { getApprenticeship, resetApprenticeship } from '../../actions/Apprenticeships';
import { setCurrentModal } from '../../actions/Modals';
import {
  FLAG_APP_VELA,
  FLAG_LOGBOOKS_VELA_READONLY,
  NOTICE_LOGBOOKS_READONLY_TITLE,
  NOTICE_MAINTENANCE_MODE_BODY
} from '../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../actions/FeatureFlags';
import { canAccessLogbook } from '../../services/currentUser';
import { fetchLogbookHours } from '../../services/logbooks';
import { RECORDS_PAGINATION_LIMIT } from '../../constants/api';
import Loading from '../../components/Loading';
import LogbookRecordsList from '../../components/Lists/LogbookRecordsList';
import ProgramContainer from '../../components/Lists/ProgramsList/ProgramContainer';
import PaginationSupabase from '../../components/PaginationSupabase';
import LogbookProgress from '../../components/LogbookProgress';
import LogbookNotFound from '../../components/LogbookNotFound';
import LogbookTabs from '../../components/Tabs/LogbookTabs';

const Records = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const flags = useFlags([FLAG_LOGBOOKS_VELA_READONLY, FLAG_APP_VELA]);
  const { getFlags } = useFlagsmith();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbook = useSelector((state) => state.logbook);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  // Current User
  const userId = currentUser?.id || '';
  const role = currentUser?.role || [];
  const apprenticeshipIds = currentUser?.apprenticeshipIds || null;
  // Params
  const searchParams = new URLSearchParams(window.location.search);
  const apprenticeshipId = searchParams.get('apprenticeshipId');
  const logbookId = searchParams.get('logbookId');
  // Organization
  const orgId = organization?.id || '';

  const initialListConfig = {
    orgId,
    logbookId,
    ...(userId ? { userId } : {}),
    limit: RECORDS_PAGINATION_LIMIT,
    page: 1
  };

  const [listConfig, setListConfig] = useState(initialListConfig);

  const handleEditRecord = (record) => {
    getFlags();

    if (flags?.logbooks_vela_readonly?.enabled && flags?.logbooks_vela_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_LOGBOOKS_READONLY_TITLE,
        modalBody: NOTICE_MAINTENANCE_MODE_BODY
        // preventClose: true
      }));
      return;
    }

    dispatch(
      setCurrentModal({
        key: 'manageRecord',
        data: {
          record
        }
      })
    );
  };

  useEffect(() => {
    if (logbookId) {
      fetchLogbookHours({
        orgId,
        logbookId,
        locationId: logbook?.locationId,
        apprenticeshipId,
        showCompHours: true
      });

      dispatch(getLogbook({
        id: logbookId,
        orgId,
        logbookHours: true,
        logbookCompHours: true
      }))
        .then((response) => {
          if (response?.orgId !== currentUser?.orgId) {
            toast.error('Logbook Organization Access Denied ', { autoClose: false });
            history.replace(SLASH);
            return;
          }

          if (response?.userId !== currentUser?.id) {
            toast.error('Logbook User Access Denied', { autoClose: false });
            history.replace(SLASH);
            return;
          }

          dispatch(getApprenticeship(response?.apprenticeshipId));
        })
        .catch((error) => {
          console.error(error);
          dispatch(errorLogbookNotFound({
            logbookId,
            orgId: organization?.id,
            orgName: organization?.name,
            userId,
            userName: currentUser.name,
            userEmail: currentUser?.email,
            userLocationId: currentUser?.locationId
          }));
        });
    }

    return function cleanup() {
      dispatch(resetApprenticeship());
      dispatch(resetLogbook());
    };
  }, []); // TODO: Fix - Currently empty due to infinite refreshes with expected dependencies in array

  if (!currentUser.id || !organization.id) {
    return (
      <Loading
        className='position-relative'
        style={{ background: '#121212' }}
      />
    );
  }

  if (!apprenticeshipIds || (Array.isArray(apprenticeshipIds) && apprenticeshipIds.length === 0)) {
    return <Redirect to={SLASH} />;
  }

  if (!canAccessLogbook(role)) {
    return <Redirect to={SLASH} />;
  }

  if (logbookId && !logbook.fetched && !logbook.id) {
    return (
      <Loading
        className='position-relative'
        style={{ background: '#121212' }}
        text="Loading Logbook..."
      />
    );
  }

  if (!logbookId || (logbook.fetched && !logbook.id)) {
    return <LogbookNotFound />;
  }

  return (
    <div>
      {logbook?.id && (
        <ProgramContainer
          className="mt-3"
          apprenticeshipId={apprenticeship?.id}
          apprenticeshipTitle={apprenticeship?.title}
          logbookId={logbookId}
        // logbookLocationId={logbook?.employerId}
        >
          <LogbookProgress
            className='w-100'
            // records={logbookRecords.list}
            ojtHours={apprenticeship?.ojtHours}
          />
        </ProgramContainer>
      )}

      <LogbookTabs
        active="records"
        ojtHours={apprenticeship?.ojtHours}
      />

      {logbook?.id && (
        <div className='theme-dark position-relative'>
          <LogbookRecordsList
            listConfig={listConfig}
            // className="theme-dark"
            logbookId={logbook?.id}
            userId={currentUser?.id}
            apprenticeshipId={apprenticeshipId}
            handleClickEdit={handleEditRecord}
          />

          {Array.isArray(logbookRecords.list) && logbookRecords.list.length > 0 && (
            <PaginationSupabase
              items={logbookRecords?.list}
              pagination={logbookRecords?.pagination}
              page={logbookRecords?.pagination?.page}
              onClickNext={() => {
                setListConfig((prev) => ({
                  ...prev,
                  page: logbookRecords?.pagination?.page + 1
                }));
                // goToElem('root');
              }}
              onClickPrev={() => {
                setListConfig((prev) => ({
                  ...prev,
                  page: logbookRecords?.pagination?.page - 1
                }));
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(Records);
