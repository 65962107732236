import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { fetchSupabaseEntries } from './supabaseProxy';

const table = 'competencies';

/**
 * @param {String} orgId
 * @param {String} employerId
 */
export const fetchCompetencies = async ({
  ids = [],
  orgId,
  compId,
  userId,
  apprenticeshipId,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    orgId,
    limit
  };

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (compId !== undefined) {
    params['f.compId[eq]'] = compId;
  }

  if (apprenticeshipId !== undefined) {
    params['f.apprenticeshipId[eq]'] = apprenticeshipId;
  }

  if (userId !== undefined) {
    params['f.userId[eq]'] = userId;
  }

  if (orgId !== undefined) {
    params['f.orgId[eq]'] = orgId;
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};
