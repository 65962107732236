export const VELA_LOGO = 'https://turbine-content.s3.us-east-2.amazonaws.com/vela-white.png';
export const VELA_ICON = 'https://turbine-content.s3.us-east-2.amazonaws.com/vela-icon-32x32.png';
export const TURBINE_LOGO = 'https://s3.us-west-2.amazonaws.com/turbineworkforce.com/turbine-workforce-logo.jpg';
export const DEFAULT_PROFILE_PIC = 'https://turbine-content.s3.us-east-2.amazonaws.com/default-profile-picture.png';
export const DEFAULT_ORG_LOGO = 'https://turbine-content.s3.us-east-2.amazonaws.com/default-org-logo.jpg';
export const NO_NOTIFICATIONS = 'https://turbine-content.s3.us-east-2.amazonaws.com/no-notifications.png';
export const SKILLS_IMG = 'https://turbine-content.s3.us-east-2.amazonaws.com/skills.png';
export const DASHBOARD_RESOURCES = 'https://turbine-content.s3.us-east-2.amazonaws.com/dashboard-resources.jpg';
export const IMAGE_MAINTENANCE = 'https://turbine-content.s3.us-east-2.amazonaws.com/maintenance.png';
// CARDS
export const IMAGE_CARD_DEFAULT = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-default.png';
export const IMAGE_CARD_LOGBOOKS = 'https://turbine-content.s3.us-east-2.amazonaws.com/tw-card-image-logbook.png';
export const IMAGE_CARD_STANDUP = 'https://turbine-content.s3.us-east-2.amazonaws.com/card-standup.jpg';
export const IMAGE_CARD_DECISION = 'https://turbine-content.s3.us-east-2.amazonaws.com/decision.png';
export const IMAGE_CARD_KNOWLEDGE_TRANSFER = 'https://turbine-content.s3.us-east-2.amazonaws.com/card-knowledge-transfer.jpg';
// INTEGRATIONS
export const MICROSOFT_TEAMS_ICON = 'https://turbine-content.s3.us-east-2.amazonaws.com/microsoft-teams-icon.png';
export const SLACK_ICON = 'https://turbine-content.s3.us-east-2.amazonaws.com/slack-icon.png';
export const STRIPE_ICON = 'https://turbine-content.s3.us-east-2.amazonaws.com/stripe-icon.png';
