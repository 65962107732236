import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseCompetencies } from '@apprentage/utils';
import Collapse from '@apprentage/components/dist/components/Collapse';
import { getUserCompetencies } from '../../actions/Competencies';
import UserCompetenciesList from '../Lists/UserCompetenciesList';
import TableLoading from '../TableLoading';
import './style.css';

const LogbookView = ({
  className = '',
  ariaExpanded,
  ariaExpandedFirstItem
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const userCompetencies = useSelector((state) => state.userCompetencies);
  // Current User
  const userId = currentUser?.id || null;
  // Organization
  const orgId = organization?.id || null;
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;
  const competencies = apprenticeship?.competencies || {};
  const competencyGroups = apprenticeship?.competencyGroups || [];
  // User Competencies
  const userCompetenciesHash = userCompetencies?.hash || null;

  useEffect(() => {
    dispatch(getUserCompetencies({
      userId,
      apprenticeshipId,
      orgId,
      limit: 1000
    }));
  }, [apprenticeshipId, dispatch, orgId, userId]);

  if (!apprenticeship?.id) {
    return (
      <TableLoading
        className='table-dark'
        skeletonClassName='bg-0d1015 h-40px'
      />
    );
  }

  return (
    <div className={`LogbookView ${className}`}>
      {!competencyGroups || (Array.isArray(competencyGroups) && competencyGroups?.length === 0) ? (
        <div>No competency groups.</div>
      ) : (
        <div>
          {competencyGroups.map((competencyGroup, i) => {
            const competencyIds = competencyGroup?.competencyIds || null;
            let childCompetencies = [];

            childCompetencies = parseCompetencies({ competencies, competencyIds, userCompetenciesHash }) || [];

            return (
              <Fragment key={`competencyGroup-${competencyGroup?.id}`}>
                <Collapse
                  id={`competencyGroup-${competencyGroup?.id}`}
                  title={competencyGroup?.description}
                  className="mb-3 Collapse text-white"
                  p={3}
                  ariaExpanded={competencyGroups.length === 1 || ariaExpanded || (i === 0 && ariaExpandedFirstItem)}
                >
                  <UserCompetenciesList
                    list={childCompetencies}
                  />
                </Collapse>
              </Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LogbookView;
