import React from 'react';
import './style.css';

const LoadingWave = ({
  className = '',
  type = '' // contained, content
}) => {
  return (
    <div
      className={`waveContainer ${type} ${className} d-flex justify-content-center align-items-center`}
    >
      <div className='waveElement' />
      <div className='waveElement' />
      <div className='waveElement' />
      <div className='waveElement' />
      <div className='waveElement' />
      <div className='waveElement' />
      <div className='waveElement' />
      <div className='waveElement' />
      <div className='waveElement' />
      <div className='waveElement' />
    </div>
  );
};

export default LoadingWave;
